<template>
	<div
		class="card-content is-flex is-flex-direction-column is-align-items-center has-text-centered"
		:class="{ 'darkmode': isDarkMode }"
	>
		<div class="column is-8">
			<h1
				v-if="!contemCobranca"
				class="is-size-2 title has-text-weight-bold sucess"
			>
				Requerimento Aberto Com Sucesso
			</h1>

			<h1
				v-if="contemCobranca && pagamentoCartaoAprovado && !pagamentoPostergado"
				class="is-size-2 title has-text-weight-bold sucess"
			>
				Requerimento Aberto Com Sucesso e Pagamento Realizado
			</h1>

			<h1
				v-if="contemCobranca && (!pagamentoCartaoAprovado || pagamentoPostergado)"
				class="is-size-2 title has-text-weight-bold danger"
			>
				Atenção
			</h1>
		</div>

		<div class="column is-8 main-warning">
			<p
				v-if="contemCobranca && !pagamentoCartaoAprovado && !pagamentoPostergado"
				class="is-size-4 alert"
			>
				{{ getRetornoPagamento.msg || 'Erro ao realizar pagamento. Revise os dados e aguarde alguns instantes, caso o erro persista, contate o atendimento.' }}
			</p>

			<p
				v-if="contemCobranca && pagamentoCartaoAprovado && !pagamentoPostergado"
				class="mt-4 is-size-5 darker"
			>
				O pagamento pela solicitação de <strong>{{ getTipoSelecionado.reqt_descricao | capitalizeAllWords }}</strong> gerou o número de requerimento <strong>{{ requId }}</strong> em <strong>{{ dateNow }}</strong> e o pagamento no valor de <strong>{{ valorRequerimento }}</strong> foi efetuado com sucesso
			</p>

			<p
				v-if="contemCobranca && pagamentoPostergado"
				class="mt-4 is-size-4 darker"
			>
				Identificamos que você iniciou a abertura de sua solicitação, mas ela aguarda o pagamento para prosseguir
			</p>

			<p
				v-if="contemCobranca && pagamentoPostergado"
				class="mt-4 is-size-5"
			>
				Você ainda pode continuar com este pedido realizando o pagamento no valor de <strong>{{ valorRequerimento }}</strong> agora ou em até <strong>{{ getTipoSelecionado.reqt_prazo_pagamento }} dias úteis</strong> a partir de <strong>{{ dateNow }}</strong>
			</p>

			<p
				v-if="contemCobranca && !pagamentoCartaoAprovado && !pagamentoPostergado"
				class="mt-4 is-size-5"
			>
				Você ainda pode continuar com este pedido realizando novamente a transação via cartão de crédito no valor de <strong>{{ valorRequerimento }}</strong> agora ou em até <strong>{{ getTipoSelecionado.reqt_prazo_pagamento }} dias úteis</strong> a partir de <strong>{{ dateNow }}</strong>
			</p>

			<p
				v-if="contemCobranca && !pagamentoCartaoAprovado && !pagamentoPostergado"
				class="mt-4 is-size-5"
			>
				Caso não realize o pagamento agora, clique no botão <strong>concluir</strong> para acompanhar sua solicitação através do menu <strong>Acompanhamento de Solicitações</strong>
			</p>

			<p
				v-if="!contemCobranca"
				class="mt-4 is-size-5 darker"
			>
				A solicitação <strong>{{ getTipoSelecionado.reqt_descricao | capitalizeAllWords }}</strong> gerou o número de requerimento <strong>{{ requId }}</strong> e tem previsão de atendimento em <strong>{{ detalhes(requId).requ_data_resposta }}.</strong>
			</p>

			<p
				v-if="!contemCobranca || (contemCobranca && pagamentoCartaoAprovado) || pagamentoPostergado"
				class="my-5 is-size-5 has-text-centered"
			>
				Clique no botão “Concluir” para acompanhar sua solicitação através do menu <strong>Acompanhamento de Solicitações</strong>.
			</p>

			<div
				v-if="contemCobranca && (!pagamentoCartaoAprovado || pagamentoPostergado)"
				class="mt-6 is-size-5 has-text-centered"
			>
				Através do menu <strong>Pagamentos</strong>, identifique a cobrança por esta solicitação dentro do prazo de <strong>{{ getTipoSelecionado.reqt_prazo_pagamento }} dias úteis</strong> para que sua solicitação seja acatada,
			</div>
		</div>

		<div
			class="column is-offset-4-desktop is-8-desktop is-12-mobile"
			:class="{
				'is-flex': !isMobile,
				'is-justify-content-space-between': contemCobranca && !pagamentoCartaoAprovado && !pagamentoPostergado,
				'is-justify-content-flex-end': !contemCobranca || (contemCobranca && pagamentoCartaoAprovado && !pagamentoPostergado),
			}"
		>
			<div
				v-if="contemCobranca && !pagamentoCartaoAprovado && !pagamentoPostergado"
				class="column is-6-desktop is-flex is-justify-content-center is-align-items-center"
			>
				<button
					class="button is-primary is-rounded"
					:class="{
						'is-fullwidth': isMobile,
					}"
					@click="handleGoToPayments"
				>
					Pagar agora
				</button>
			</div>

			<div
				class="column is-6-desktop"
				:class="{
					'is-flex': !contemCobranca,
					'is-justify-content-flex-end': !contemCobranca,
				}"
			>
				<button
					class="button is-primary is-rounded"
					:class="{
						'is-outlined': contemCobranca,
						'is-fullwidth': isMobile,
					}"
					@click="nextStep"
				>
					Concluir
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { capitalizeAll } from '@commons/helpers';
import moment from 'moment';

export default {
	name: 'NovaSolicitacaoModalAviso',
	filters: {
		capitalizeAllWords(value) {
			return capitalizeAll(value);
		}
	},

	props: {
		requId: {
			type: [Number, String],
			required: true,
		},
	},

	computed: {
		...mapGetters('solicitacoes', [
			'getValorCobranca',
			'getTipoSelecionado',
			'contemCobranca',
			'detalhes',
			'getRetornoPagamento'
		]),

		...mapGetters('ui', [
			'isMobile',
		]),

		solicitacao() {
			return this.$store.getters['solicitacoes/detalhes'](this.requId);
		},

		pagamentoCartaoAprovado() {
			return this.getRetornoPagamento != null && this.getRetornoPagamento.success;
		},

		pagamentoPostergado() {
			return this.getRetornoPagamento == null;
		},

		dateNow() {
			return moment().format('DD/MM/YYYY');
		},

		valorRequerimento() {
			return Intl.NumberFormat('pt-BR', {style:'currency', currency: 'BRL'}).format(this.getTipoSelecionado.reqt_valor_default)
		},

		isDarkMode() {
			return this.$store.state.ie.sigla === 'ebr';
		},
	},

	created() {
		this.setProgress(100);
	},

	methods: {
		...mapMutations('solicitacoes', [
			'setModalActive',
			'setActiveStep',
			'setProgress',
			'setSecaoSelecionada',
			'setCategoriaSelecionada',
			'setTipoSelecionado',
		]),

		cleanState() {
			this.setProgress(0);
			this.setModalActive(false);
			this.setActiveStep('aviso');
			this.setSecaoSelecionada(null);
			this.setCategoriaSelecionada(null);
			this.setTipoSelecionado(null);
		},

		handleGoToPayments() {
			this.cleanState();
			this.$emit('go-to', 'acompanhamento');
		},

		nextStep() {
			this.cleanState();
			this.$emit('go-to', 'acompanhamento');
		}
	},
}
</script>


<style lang="scss" scoped>
.darkmode {
	& h1, p, div {
		color: var(--primary-100)!important;
	}
}

.alert {
	color: #FFB100;
}

.title {
	color: #173048;
}

.main-warning {
	color: #525252
}

.darker {
	color: #777777
}

.sucess {
	color: #03a000
}

.danger {
	color: #ec2020
}
</style>
