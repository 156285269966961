<template>
	<!-- eslint-disable vue/no-v-html -->

	<div>
		<div class="page-header columns is-gapless is-flex">
			<UIInput
				id="search-text"
				v-model="searchText"
				label="Pesquisar Solicitação"
				class="column is-8 is-10-mobile mr-2"
			/>

			<div class="column is-4 is-2-mobile is-flex is-justify-content-center">
				<button
					v-if="isMobile"
					class="filter-list"
					:disabled="isLoading"
					@click="filterList()"
				>
					<b-icon icon="magnify" />
				</button>

				<b-button
					v-else
					class="filter-list ml-4"
					type="is-primary"
					expanded
					rounded
					outlined
					label="Pesquisar"
					:disabled="isLoading"
					@click="filterList()"
				/>
			</div>
		</div>

		<div class="page-main my-5">
			<b-loading
				v-if="isLoading"
				v-model="isLoading"
				:is-full-page="false"
				:can-cancel="false"
			/>

			<div
				v-else-if="listagem.length === 0"
				class="is-flex is-flex-direction-column is-align-items-center no-content"
			>
				<h3
					class="is-size-5 has-text-centered"
					:class="{ 'darkmode': isDarkMode }"
				>
					{{
						isSearching
							? 'Sua pesquisa não encontrou nenhuma solicitação'
							: 'Você ainda não possui nenhuma solicitação registrada.'
					}}
				</h3>

				<p
					class="column is-12 is-size-6 has-text-centered"
					v-html="noContentParagraph"
				/>
			</div>

			<div v-else class="yolo">
				<b-table
					:data="listagemPaginada"
					backend-sorting
					:mobile-cards="false"
					class="table"
					detail-key="requ_requerimento"
				>
					<b-table-column
						v-slot="props"
						field="curs_nome"
						label="Curso"
						centered
					>
						{{ toLowerWithFirstUpper(props.row.curs_nome) }}
					</b-table-column>

					<b-table-column
						v-slot="props"
						field="requ_requerimento"
						label="Nº Requerimento"
						centered
						numeric
					>
						{{ props.row.requ_requerimento }}
					</b-table-column>

					<b-table-column
						v-slot="props"
						field="requ_descricao_requerimento"
						label="Nome do requerimento"
						centered
					>
						{{ toLowerWithFirstUpper(props.row.reqt_descricao) }}
					</b-table-column>

					<b-table-column
						v-slot="props"
						field="requ_data_solicitacao"
						label="Aberto em"
						centered
					>
						{{ props.row.requ_data_solicitacao }}
					</b-table-column>

					<b-table-column
						v-slot="props"
						field="requ_data_resposta"
						label="Resposta em"
						centered
					>
						<span
							:class="{
								'is-danger': !props.row.yolo
							}"
						>
							{{ props.row.requ_data_resposta }}
						</span>
					</b-table-column>

					<b-table-column
						v-slot="props"
						field="reqs_descricao_web"
						label="Status"
						centered
					>
						<span
							:class="{
								'is-danger': !props.row.yolo
							}"
						>
							{{ toLowerWithFirstUpper(props.row.reqs_descricao_web) }}
						</span>
					</b-table-column>

					<b-table-column v-slot="props" label="Detalhes" centered>
						<b-button
							v-if="
								props.row.reqs_descricao_web.toUpperCase() === 'FINALIZADO' &&
									props.row.requ_avaliado !== 't' &&
									isPrazoFeedback(props.row)
							"
							:disabled="props.row.reqt_visualiza_andamento_web != 't'"
							rounded
							:class="getDetailButtonClasses(props.row)"
							@click="handleFeedbackModal(props.row)"
						>
							Avaliar
						</b-button>

						<b-button
							v-else
							:disabled="props.row.reqt_visualiza_andamento_web != 't'"
							rounded
							:class="getDetailButtonClasses(props.row)"
							@click="handleDetailAction(props.row)"
						>
							{{ toLowerWithFirstUpper(props.row.status_detalhe) }}
						</b-button>
					</b-table-column>
				</b-table>
			</div>
		</div>

		<div class="page-footer columns">
			<div class="column is-8 is-12-mobile">
				<Pagination
					v-show="!isLoading"
					v-model="page"
					:per-page="5"
					:total="listagemFiltrada.length"
					hide-on-single-page
				/>
			</div>

			<div class="column is-4 is-hidden-mobile">
				<b-button
					class="is-primary is-rounded is-fullwidth"
					@click="$emit('createNew')"
				>
					Fazer nova solicitação
				</b-button>
			</div>
		</div>

		<AvaliarSolicitacaoModal
			:requ-id="requ_id"
			:row="feedbackModalData"
			@handleDetailAction="handleDetailAction"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Pagination from '@components/Pagination';
import UIInput from '@components/uiinput';
import AvaliarSolicitacaoModal from './modal/AvaliarSolicitacaoModal.vue';

export default {
	name: 'Acompanhamento',
	components: {
		Pagination,
		UIInput,
		AvaliarSolicitacaoModal
	},

	data() {
		return {
			loading: false,
			page: 0,
			isSearching: false,
			searchText: '',
			filterId: false,
			feedbackModalOpen: false,
			feedbackModalData: {},
			requ_id: null
		};
	},

	computed: {
		...mapGetters('ui', ['isMobile']),

		...mapGetters('solicitacoes', ['listagem', 'temFiltro']),

		...mapGetters('vinculosAcademicos', ['getVinculoSelecionado']),

		listagemPaginada() {
			return this.listagemFiltrada.slice(this.page * 5, 5 + this.page * 5);
		},

		listagemFiltrada() {
			if (!this.filterId) {
				return this.$store.getters['solicitacoes/listagemOrdenada'];
			}

			return this.$store.getters['solicitacoes/listagemOrdenada'].filter(
				r => String(r.requ_requerimento).search(this.filterId) !== -1
			);
		},

		isLoading() {
			return !this.$store.getters['ui/isLoading'] && this.loading;
		},

		noContentParagraph() {
			return this.isSearching
				? 'Tente trocar os termos usados e pesquise novamente'
				: 'Quando fizer uma solicitação, ela aparecerá aqui<br>\nneste painel para seu acompanhamento.';
		},

		isDarkMode() {
			return this.$store.state.ie.sigla === 'ebr';
		},
	},

	watch: {
		getVinculoSelecionado() {
			this.$store
				.dispatch('ui/loading', true)
				.then(() => this.filterList())
				.then(() => this.$store.dispatch('ui/loading', false));
		}
	},

	async created() {
		this.filterList();
	},

	methods: {

		...mapActions('pagamentos', [
			'todosPagamentos'
		]),

		...mapMutations('solicitacoes', [
			'setOpenModalDetalhe',
		]),

		async filterList() {
			if (!this.temFiltro) {
				const filtro = this.searchText.trim();

				const soNumeros = parseInt(filtro, 10).toString() == filtro;

				if (soNumeros) {
					this.page = 0;
					this.filterId = filtro;

					return;
				}
			}

			this.page = 0;
			this.filterId = false;
			this.loading = true;
			this.isSearching = Boolean(this.searchText.length);

			try {
				await this.$store.dispatch('solicitacoes/carregar', this.searchText);
			} catch (e) {
				this.$store.dispatch(
					'ui/toast/openError',
					String(e) || 'Não foi possível buscar'
				);
			} finally {
				this.loading = false;
			}
		},

		toLowerWithFirstUpper(str) {
			return String(str || '-')
				.trim()
				.toLowerCase()
				.replace(/^./, m => m.toUpperCase());
		},

		getDetailButtonClasses(row) {
			const statusDetalhe = row.status_detalhe;

			if (row.reqs_descricao_web.toUpperCase() === 'FINALIZADO') {
				return ['is-primary'];
			}

			if (statusDetalhe === 'PAGAR') {
				return ['is-danger', 'destaque-vermelho'];
			} else if (statusDetalhe === 'ACOMPANHAR') {
				return ['is-primary'];
			} else {
				return ['is-primary', 'is-outlined'];
			}
		},

		isPrazoFeedback(row) {
			if (row.reqs_descricao_web.toUpperCase() === 'FINALIZADO') {
				const dataFinalizado = new Date(row.datahora_raw);
				dataFinalizado.setDate(dataFinalizado.getDate() + 10);

				const today = new Date();

				if (dataFinalizado.getTime() > today.getTime()) return true;
			}

			return false;
		},

		handleDetailAction(row) {
			const statusDetalhe = row.status_detalhe;

			if (statusDetalhe === 'PAGAR') {
				// this.todosPagamentos();

				this.$router.push({
					name: 'meus-pagamentos',
					params: {
						tab: 1
					}
				});
			} else {
				this.$emit('showDetailed', parseInt(row.requ_requerimento, 10));
			}
		},

		handleFeedbackModal(row) {
			this.setOpenModalDetalhe(true);
			this.requ_id = parseInt(row.requ_id, 10);
			this.feedbackModalData = row;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/_variables';

.darkmode {
	color: var(--primary-100)!important;
}

.page-header {
	.filter-list {
		padding: 1.6rem 0;
	}
}

@media screen and (max-width: 768px) {
	.page-header {
		.filter-list {
			width: 100%;
			padding: 0;
			background-color: transparent !important;
			border-color: transparent !important;
			color: var(--text);
			cursor: pointer;

			&[disabled] {
				color: #999999 !important;
				cursor: not-allowed;
			}
		}
	}
}

.page-main {
	position: relative;
	min-height: 12rem;

	.no-content {
		padding-top: 1.5rem !important;
		padding-bottom: 5.5rem !important;

		h3 {
			color: $dark-blue;
			color: var(--dark-blue);
		}
	}
}

::v-deep {
	.table thead td,
	.table thead th {
		border-bottom-width: 1px;
		border-color: #e9ebee;
		color: #173048;
		font-size: 16px;
		line-height: 24px;
	}

	.table td,
	.table th {
		vertical-align: middle;
		font-size: 12px;
		line-height: 16px;
	}
}

.button.destaque-vermelho {
	background-color: #ff1515 !important;
}

.page-footer {
	position: relative;

	button.create-new {
		position: absolute;
		top: 0;
		right: 0;
	}
}
</style>
